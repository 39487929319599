import BaseModel from "@/models/BaseModel";

export default class SuiviTerritoirePriseContact extends BaseModel {
  static entity = "suivi_territoire_prise_contact";
  static resourceUrl = "/suivi_territoire/prise_contact/";

  static apiDateKey = "date_echange";

  static fields() {
    return {
      id: this.uid(),
      entites: this.attr([]),
      date_echange: this.attr(),
      type_echange: this.attr(),
      moyen_contact: this.attr([]),
      moyen_contact_autre: this.attr(),
      interlocuteurs: this.attr([]),
      commentaires: this.attr(),
      synthese: this.attr(),
      themes: this.attr([]),
      created_datetime: this.attr(),
      updated_datetime: this.attr(),
      created_user: this.attr(),
      updated_user: this.attr(),
    };
  }

  // api_expose permissions
  static PERMISSION = Object.freeze({
    READ: "suivi_territoire:prisecontact_read",
    MANAGE: "suivi_territoire:prisecontact_manage",
  });

  static TYPES = [
    { text: "Temps de suivi", value: "TEMPS_SUIVI" },
    { text: "Visite de territoire", value: "VISITE_TERRITOIRE" },
    { text: "Réunion de pilotage", value: "REUNION_PILOTAGE" },
    { text: "Autre·s information·s importante·s à notifier", value: "AUTRE" },
  ];

  static normalize(model) {
    return {
      id: model.id,
      key: `contact-${model.id}`,
      date: model.date_echange,
      user: model.created_user ? `${model.created_user.first_name} ${model.created_user.last_name}` : "",
      description: model.synthese,
      category: "contacts",
      entites: model.entites,
    };
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      async loadAndNormalize(params) {
        const [rawResults, count] = await this.loadWithResults(params);
        const results = (rawResults || []).map(this.model.normalize);
        return { results, count };
      },
    },
  };
}
