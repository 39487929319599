import { downloadFile } from "@/helpers/ApiRequest";
import BaseModel from "@/models/BaseModel";
import Group from "@/models/notrexp/Group";

export default class User extends BaseModel {
  static entity = "user";
  static resourceUrl = "/users/";

  static fields() {
    return {
      email: this.attr(""),
      first_name: this.attr(""),
      id: this.uid(),
      last_name: this.attr(""),
      groups: this.attr([]),
      permissions: this.attr([]),
      is_staff: this.attr(null),
      date_joined: this.attr(),
      last_login: this.attr(),
      ebes: this.attr(),
      cles: this.attr(),

      // included in the extra_infos
      user_profile: this.attr(null),
    };
  }

  static PERMISSION = Object.freeze({
    READ: "notrexp:user_read",
    MANAGE: "notrexp:user_management",
    MANAGE_LISTE_DIFFUSION: "notrexp:user_liste_diffusion_management",
    MANAGE_COMMENT: "notrexp:user_comment_update",
  });

  static STAFF_TYPE_PAT = "staff_pat";
  static STAFF_TYPE_PAF = "staff_paf";

  getStaffType() {
    if (this.groups.includes(Group.ETCLD.GESTIONNAIRE_PAT)) {
      return User.STAFF_TYPE_PAT;
    } else if (this.groups.includes(Group.ETCLD.GESTIONNAIRE_PAF)) {
      return User.STAFF_TYPE_PAF;
    }
    return "";
  }

  get type() {
    return ["etcld", "cle", "ebe"].find((type) => this.groups.some((group) => group.includes(`${type}_`))) || null;
  }

  static apiConfig = {
    actions: {
      createUser(data, config) {
        return this.post("/users/", data, config);
      },
      getCurrent(config) {
        return this.get("/users/current/", config);
      },
      disable(user) {
        return this.post(`/users/${user.id}/disable/`);
      },
      checkChangePasswordToken(token, config) {
        return this.post(
          "/users/change_password/",
          {
            token: token,
            check_token: true,
          },
          config
        );
      },
      changePassword(token, password) {
        return this.post("/users/change_password/", {
          token: token,
          password: password,
        });
      },
      async exportXlsx(params) {
        const urlParams = new URLSearchParams(params);
        const url = `${process.env.VUE_APP_API_URL}${User.resourceUrl}export_xlsx/?${urlParams.toString()}`;
        await downloadFile(url);
      },
      ...BaseModel.apiConfig.actions,
    },
  };
}
