import BaseModel from "@/models/BaseModel";

export default class StaffSuivi extends BaseModel {
  static entity = "staff_suivi";
  static resourceUrl = "/suivi_territoire/staff_suivi/";

  static fields() {
    return {
      id: this.uid(),
      entite: this.attr(),
      in_wallet: this.attr(),
      subscribe_notification: this.attr(),
    };
  }

  static PERMISSION = Object.freeze({
    WALLET_CLE_EBE: "suivi_territoire:staffsuivi",
    WALLET_DEPARTEMENT: "suivi_territoire:staffsuivi_departement",
    SUBSCRIBE: "suivi_territoire:staffsuivi_subscribe",
  });
}
