import BaseModel from "@/models/BaseModel.js";
import Ebe from "@/models/Ebe.js";
import DepartementEntite from "@/models/notrexp/DepartementEntite";
import GroupEntite from "@/models/notrexp/GroupEntite";

export default class Cle extends BaseModel {
  static entity = "cle";
  static resourceUrl = "/cle/";

  static TITLE = Object.freeze({
    MADAME: "MADAME",
    MONSIEUR: "MONSIEUR",
    NON_COMMUNIQUE: "NON_COMMUNIQUE",
  });

  static TITLE_ITEMS = Object.freeze([
    { text: "Madame", value: Cle.TITLE.MADAME },
    { text: "Monsieur", value: Cle.TITLE.MONSIEUR },
    { text: "Non communiqué", value: Cle.TITLE.NON_COMMUNIQUE },
  ]);

  static fields() {
    return {
      id: this.uid(),
      nom: this.string(""),
      created_datetime: this.attr(),
      // included in the createCle
      utilisateur: this.attr(),
      // included in the extra_infos
      date_arrete_habilitation: this.attr(),
      epci: this.attr(),
      slug: this.attr(),
      villes: this.attr(),
      adresse: this.attr(),
      presidents: this.attr(),
      departements: this.attr(),
      // included in the suivi_infos
      staffsuivicle_users: this.attr(),
    };
  }

  static PERMISSION = Object.freeze({
    MANAGE: "notrexp:cle_manage",
    UPDATE: "notrexp:cle_update",
  });

  get entityType() {
    return "CLE";
  }

  getEbes() {
    return Ebe.query()
      .where((ebe) => ebe.cles.includes(this.id))
      .orderBy("nom")
      .get();
  }

  getDepartements() {
    return DepartementEntite.query().whereIdIn(this.departements).orderBy("nom").all();
  }

  getGroups() {
    return GroupEntite.query()
      .where((group) => group.entites.includes(this.id))
      .orderBy("nom")
      .get();
  }

  get relatedEntities() {
    const ebes = this.getEbes();
    const departements = this.getDepartements();

    return [...ebes, ...departements];
  }

  get color() {
    return Cle.getColor();
  }
  get icon() {
    return Cle.getIcon();
  }

  static getColor() {
    return {
      text: "info--text",
      background: "info-bg",
      activeBackground: "info",
    };
  }

  static getIcon() {
    return "mdi-town-hall";
  }
}
