import BaseModel from "@/models/BaseModel";
import Ebe from "../Ebe";
import Cle from "../Cle";
import DepartementEntite from "./DepartementEntite";

export default class GroupEntite extends BaseModel {
  static entity = "group_entite";
  static resourceUrl = "/notrexp/group_entite/";

  static fields() {
    return {
      id: this.uid(),
      entites: this.attr([]),
      nom: this.attr(""),
      description: this.attr(""),
    };
  }

  getEbes() {
    return Ebe.query().whereIdIn(this.entites).orderBy("nom").all();
  }

  getCles() {
    return Cle.query().whereIdIn(this.entites).orderBy("nom").all();
  }

  getDepartements() {
    return DepartementEntite.query().whereIdIn(this.entites).orderBy("nom").all();
  }

  async removeEntite(memberId) {
    const updated_entites = this.entites.filter((member) => {
      return member != memberId;
    });
    const updated_group = new GroupEntite({
      id: this.id,
      nom: this.nom,
      description: this.description,
      entites: updated_entites,
    });
    await GroupEntite.api().save(updated_group);
  }

  get color() {
    return GroupEntite.getColor();
  }
  get icon() {
    return GroupEntite.getIcon();
  }

  static getColor() {
    return {
      text: "blue--text",
      background: "blue lighten-5",
      activeBackground: "blue",
    };
  }

  static getIcon() {
    return "mdi-tag-multiple";
  }
}
