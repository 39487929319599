import BaseModel from "@/models/BaseModel";

export default class AgefiphSaisieEbe extends BaseModel {
  static entity = "agefiph_saisie_ebe";
  static resourceUrl = "/agefiph/agefiph_saisie_ebe/";

  static fields() {
    return {
      id: this.uid(),
      ebe: this.attr(),
      annee: this.attr(),
      export_pdf: this.attr(),
    };
  }
}
