import axios from "axios";

import BaseModel from "@/models/BaseModel";

export default class AttachedS3File extends BaseModel {
  static entity = "attached_s3_file";
  static resourceUrl = "/attached_s3_file/";

  static AUDIO = Object.freeze({
    TITLE: "Audio",
    TYPES: Object.freeze(["audio/mpeg", "audio/ogg", "audio/webm", "audio/wave", "audio/wav", "audio/x-wav", "audio/x-pn-wav", "audio/mp4"]),
    EXTENSIONS: Object.freeze(["mp3", "ogg", "wav", "m4a"]),
  });

  static VIDEO = Object.freeze({
    TITLE: "Vidéo",
    TYPES: Object.freeze(["video/webm", "video/ogg", "video/mp4", "video/quicktime", "video/x-msvideo", "video/x-matroska"]),
    EXTENSIONS: Object.freeze(["mp4", "avi", "mov", "webm", "mkv"]),
  });

  static IMAGE = Object.freeze({
    TITLE: "Image",
    TYPES: Object.freeze(["image/jpeg", "image/png", "image/webp", "image/heic", "image/heif"]),
    EXTENSIONS: Object.freeze(["jpg", "jpeg", "png", "heif", "heic", "webp"]),
  });

  static DOCUMENT = Object.freeze({
    TITLE: "Document",
    TYPES: Object.freeze([
      "application/pdf",
      "application/vnd.oasis.opendocument.text",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ]),
    EXTENSIONS: Object.freeze(["pdf", "odt", "doc", "docx"]),
  });

  static PDF = Object.freeze({
    TITLE: "PDF",
    TYPES: Object.freeze(["application/pdf"]),
    EXTENSIONS: Object.freeze(["pdf"]),
  });

  static ICON_MAP = Object.freeze({
    "mdi-volume-high": [AttachedS3File.AUDIO],
    "mdi-video": [AttachedS3File.VIDEO],
    "mdi-image": [AttachedS3File.IMAGE],
    "mdi-file-document": [AttachedS3File.DOCUMENT, AttachedS3File.PDF],
  });

  static STATUS = Object.freeze({
    UPLOADING: "UPLOADING",
    UPLOADED: "UPLOADED",
    DELETED: "DELETED",
  });

  static fields() {
    return {
      id: this.uid(),
      title: this.attr(),
      description: this.attr(),
      original_name: this.attr(),
      content_type: this.attr(),
      content_length: this.attr(),
      download_url: this.attr(),
      upload_presigned_url: this.attr(),
      status_log: this.attr(),
    };
  }

  get icon() {
    for (const [key, value] of Object.entries(AttachedS3File.ICON_MAP)) {
      const extensionsAndTypes = [...value.map(({ EXTENSIONS }) => EXTENSIONS), ...value.map(({ TYPES }) => TYPES)].flat();
      if (extensionsAndTypes.includes(this.content_type)) {
        return key;
      }
    }
    return "mdi-paperclip";
  }

  get nameWithoutExtension() {
    const chunks = this.original_name.split(".");
    chunks.pop();
    return chunks.join(".");
  }

  get extension() {
    return this.original_name.split(".").at(-1);
  }

  async upload({ data, signal, onProgressChange = () => {} }) {
    return await AttachedS3File.api().upload({
      url: this.upload_presigned_url,
      data,
      signal,
      onProgressChange,
    });
  }

  async download() {
    return await AttachedS3File.api().download({
      id: this.id,
    });
  }

  async remove() {
    return await AttachedS3File.api().remove({
      id: this.id,
    });
  }

  async check() {
    const response = await AttachedS3File.api().check({
      id: this.id,
    });
    AttachedS3File.update({
      where: this.id,
      data: response,
    });
  }

  async getDownloadURL() {
    return await AttachedS3File.api().getDownloadURL({
      id: this.id,
    });
  }

  static apiConfig = {
    actions: {
      async requestUpload({ target, entityId, title, description, file }) {
        const response = await this.post(`${this.model.resourceUrl}request/`, {
          target,
          target_id: entityId,
          title,
          description,
          original_name: file.name,
          content_type: file.type,
          content_length: file.size,
        });
        return {
          file,
          s3File: response.entities[this.model.entity][0],
        };
      },
      async upload({ url, data, signal, onProgressChange }) {
        return axios.put(url, data, {
          signal,
          headers: {
            "Content-Type": data.type,
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            onProgressChange(progress);
          },
        });
      },
      async download({ id }) {
        const s3FileResponse = await this.get(`${this.model.resourceUrl}${id}/`);
        const s3File = s3FileResponse.entities[this.model.entity][0];
        const { original_name, download_url } = s3File;
        const link = document.createElement("a");
        link.href = download_url;
        link.setAttribute("download", original_name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      async remove({ id }) {
        return await this.delete(`${this.model.resourceUrl}${id}/`);
      },
      async check({ id }) {
        const response = await this.post(`${this.model.resourceUrl}${id}/refresh_status/`);
        return response.entities[this.model.entity][0];
      },
      async getDownloadURL({ id }) {
        const s3FileResponse = await this.get(`${this.model.resourceUrl}${id}/`);
        const s3File = s3FileResponse.entities[this.model.entity][0];
        return s3File.download_url;
      },
    },
  };
}
