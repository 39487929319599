import BaseModel from "@/models/BaseModel";

export default class ContactProfileFonction extends BaseModel {
  static entity = "contact_profile_fonction";
  static resourceUrl = "/annuaire/fonction/";

  static USER_TYPE = Object.freeze({
    ETCLD: "ETCLD",
    CLE: "CLE",
    EBE: "EBE",
    DEPARTEMENT: "DEP",
    EXTERNE: "EXT",
  });

  static fields() {
    return {
      id: this.uid(),
      titre: this.attr(),
      user_type: this.attr(),
    };
  }

  static getETCLDFonctions() {
    return this.query()
      .where((fonction) => fonction.user_type.includes(this.USER_TYPE.ETCLD))
      .get();
  }

  static getCLEFonctions() {
    return this.query()
      .where((fonction) => fonction.user_type.includes(this.USER_TYPE.CLE))
      .get();
  }

  static getEBEFonctions() {
    return this.query()
      .where((fonction) => fonction.user_type.includes(this.USER_TYPE.EBE))
      .get();
  }

  static getExterneFonctions() {
    return this.query()
      .where((fonction) => fonction.user_type.includes(this.USER_TYPE.EXTERNE))
      .get();
  }

  static getContactFonctions() {
    return this.query()
      .where((fonction) => !fonction.user_type.includes(this.USER_TYPE.ETCLD))
      .get();
  }

  get typeTitle() {
    if (this.user_type.includes(ContactProfileFonction.USER_TYPE.EXTERNE)) return "Externe";
    if (this.user_type.includes(ContactProfileFonction.USER_TYPE.DEPARTEMENT)) return "Département";
    return this.user_type;
  }

  get fullTitle() {
    return `${this.typeTitle} - ${this.titre}`;
  }
}
