const SimpleContainer = () => import("@/views/SimpleContainer.vue");
const ListViewContainer = () => import("@/views/ListViewContainer.vue");

const ETCLDCleList = () => import("@/views/cle/ETCLDCleList.vue");

const ETCLDSuiviTerritoireWrapper = () => import("@/views/suivi_territoire/ETCLDSuiviTerritoireWrapper.vue");
const ETCLDSuiviTerritoireGroupList = () => import("@/views/suivi_territoire/ETCLDSuiviTerritoireGroupList.vue");

const ETCLDPilotagesEbe = () => import("@/views/pilotage/ETCLDPilotageList.vue");
const ManageRealiseEconomique = () => import("@/views/etcld/ManageRealiseEconomique.vue");
const EbeRealiseEconomique = () => import("@/views/fec/EbeRealiseEconomique.vue");
const ETCLDPilotagesCle = () => import("@/views/pilotage_cle/ETCLDPilotageList.vue");
const ManageCategories = () => import("@/views/pilotage/ManageCategories.vue");

const EtpYears = () => import("@/views/financement/previsionEtp/list/EtcldEtpYears.vue");
const EtpMonths = () => import("@/views/financement/previsionEtp/view/EtpMonths.vue");

const SuiviDSN = () => import("@/views/etcld/SuiviDSN.vue");

const gestionNotreXP = [
  {
    path: "/etcld/gestion",
    component: SimpleContainer,
    meta: {
      title: "Gestion NotreXp",
      icon: "$etcld",
      displayInMenu: true,
      disabled: true,
      groups: ["etcld_gestionnaire_pat"],
    },
    children: [
      {
        path: "/etcld/gestion/cle",
        name: "etcld-cle-list",
        component: ETCLDCleList,
        meta: {
          title: "Gestion des CLE",
          displayInMenu: true,
          permissions: ["notrexp:cle_manage"],
        },
      },
      {
        path: "/etcld/gestion/cle/:id",
        name: "etcld-cle",
        component: ETCLDCleList,
        meta: {
          permissions: ["notrexp:cle_manage"],
        },
      },
    ],
  },
];

const suiviTerritoire = [
  {
    path: "/etcld/suivi-territoire",
    component: SimpleContainer,
    meta: {
      title: "Suivi des territoires",
      icon: "mdi-magnify-expand",
      displayInMenu: true,
      disabled: true,
      groups: ["etcld_gestionnaire_pat", "etcld_gestionnaire_paf", "etcld_contributeur", "etcld_lecture_seule"],
    },
    children: [
      {
        path: "/etcld/suivi-territoire/suivi",
        name: "etcld-suivi-territoire-list",
        component: ETCLDSuiviTerritoireWrapper,
        meta: {
          title: "Outil de suivi des territoires",
          displayInMenu: true,
          groups: ["etcld_gestionnaire_pat", "etcld_gestionnaire_paf", "etcld_contributeur", "etcld_lecture_seule"],
        },
      },
      {
        path: "/etcld/suivi-territoire/suivi/:type/:id", // type is cle, ebe or departement
        name: "etcld-suivi-territoire-item",
        component: ETCLDSuiviTerritoireWrapper,
        meta: {
          groups: ["etcld_gestionnaire_pat", "etcld_gestionnaire_paf", "etcld_contributeur", "etcld_lecture_seule"],
        },
      },
      {
        path: "/etcld/suivi-territoire/group",
        name: "etcld-suivi-territoire-group-list",
        component: ETCLDSuiviTerritoireGroupList,
        meta: {
          title: "Gestion des groupes",
          displayInMenu: false,
          groups: ["etcld_gestionnaire_pat", "etcld_gestionnaire_paf", "etcld_contributeur", "etcld_lecture_seule"],
        },
      },
      {
        path: "/etcld/suivi-territoire/meteo",
        name: "etcld-suivi-territoire-meteo-list",
        component: ETCLDSuiviTerritoireWrapper,
        meta: {
          title: "Météo des territoires",
          displayInMenu: true,
          groups: ["etcld_gestionnaire_pat", "etcld_gestionnaire_paf", "etcld_contributeur", "etcld_lecture_seule"],
        },
      },
    ],
  },
];

const saisiePilotage = [
  {
    path: "/etcld/pilotage",
    component: SimpleContainer,
    meta: {
      title: "Saisies de pilotage",
      icon: "mdi-bullseye-arrow",
      displayInMenu: true,
      disabled: true,
      groups: ["etcld_gestionnaire_pat", "etcld_gestionnaire_paf", "etcld_contributeur", "etcld_lecture_seule"],
      // menuGroup: /\/etcld\/(pilotage|cle)\//,
    },
    children: [
      {
        path: "/etcld/pilotage/ebe/previsions",
        name: "etcld-pilotage-ebe-list",
        component: ETCLDPilotagesEbe,
        meta: {
          title: "EBE - Prévisions",
          displayInMenu: true,
          permissions: ["pilotage:pilotage_read"],
        },
      },
      {
        path: "/etcld/pilotage/ebe/previsions/:id",
        name: "etcld-pilotage-ebe",
        component: ETCLDPilotagesEbe,
        meta: {
          groups: ["etcld_gestionnaire_pat", "etcld_gestionnaire_paf", "etcld_contributeur", "etcld_lecture_seule"],
        },
      },

      {
        path: "/etcld/pilotage/ebe/realises",
        name: "etcld-realise-economique-list",
        component: ListViewContainer,
        meta: {
          title: "EBE - Réalisés (FEC)",
          displayInMenu: true,
          permissions: ["fec:fecimport_read"],
          listComponent: ManageRealiseEconomique,
        },
        children: [
          {
            path: ":id",
            name: "etcld-realise-economique",
            component: EbeRealiseEconomique,
            props: true,
            meta: {
              groups: ["etcld_gestionnaire_pat", "etcld_gestionnaire_paf", "etcld_contributeur", "etcld_lecture_seule"],
            },
          },
        ],
      },

      {
        path: "/etcld/pilotage/ebe/activites",
        name: "etcld-pilotage-manage-activite",
        component: ManageCategories,
        meta: {
          title: "EBE - Paramétrages activités",
          displayInMenu: true,
          permissions: ["notrexp:ebeactivitecategorie_read"],
        },
      },

      {
        path: "/etcld/pilotage/cle",
        name: "etcld-pilotage-cle-list",
        component: ETCLDPilotagesCle,
        meta: {
          title: "CLE - Formulaires",
          displayInMenu: true,
          permissions: ["pilotage_cle:pilotagecle_read"],
        },
      },
      {
        path: "/etcld/pilotage/cle/:id",
        name: "etcld-pilotage-cle",
        component: ETCLDPilotagesCle,
        meta: {
          permissions: ["pilotage_cle:pilotagecle_read"],
        },
      },
    ],
  },
];

const donneesFinancement = [
  {
    path: "/etcld/financement",
    component: SimpleContainer,
    meta: {
      title: "Données financement",
      icon: "mdi-cash-register",
      displayInMenu: true,
      disabled: true,
      groups: ["etcld_gestionnaire_pat", "etcld_gestionnaire_paf", "etcld_contributeur", "etcld_lecture_seule"],
    },
    children: [
      {
        path: "/etcld/financement/declaration-dsn-mensuelle",
        name: "etcld-suivi-dsn",
        component: SuiviDSN,
        meta: {
          title: "EBE - Données issues de la paie",
          displayInMenu: true,
          permissions: ["financement:dsnimport_read"],
        },
      },
      {
        path: "/etcld/financement/declaration-dsn-mensuelle/:dsnId",
        component: SuiviDSN,
        name: "etcld-suivi-dsn-id",
        meta: {
          title: "EBE - Données mensuelles de paie",
          permissions: ["financement:dsnimport_read"],
        },
      },

      {
        path: "/etcld/financement/prevision-etp",
        name: "etcld-financement-prevision-etp-list",
        component: ListViewContainer,
        meta: {
          title: "EBE - Prévisions ETP mensuels",
          displayInMenu: true,
          permissions: ["financement:ebeprevisionetp_read"],
          groups: ["etcld_gestionnaire_pat", "etcld_gestionnaire_paf", "etcld_contributeur", "etcld_lecture_seule"],
          listComponent: EtpYears,
        },
        children: [
          {
            path: ":id",
            name: "etcld-financement-prevision-etp",
            component: EtpMonths,
            meta: {
              permissions: ["financement:ebeprevisionetp_read", "financement:ebeprevisionetp_manage"],
              groups: ["etcld_gestionnaire_pat", "etcld_gestionnaire_paf", "etcld_contributeur", "etcld_lecture_seule"],
            },
          },
        ],
      },
    ],
  },
];

export default [...gestionNotreXP, ...suiviTerritoire, ...saisiePilotage, ...donneesFinancement];
