import VuexORM from "@vuex-orm/core";
import VuexORMAxios from "@vuex-orm/plugin-axios";

import ApiRequest from "@/helpers/ApiRequest";

import ConstantData from "@/models/ConstantData";
import User from "@/models/User";
import UserActivityLog from "@/models/notrexp/UserActivityLog";
import Group from "@/models/notrexp/Group";
import CdeEtat from "@/models/notrexp/CdeEtat";
import Cle from "@/models/Cle";
import Ebe from "@/models/Ebe";
import EbeActiviteCategorie from "@/models/notrexp/EbeActiviteCategorie";
import EbeActiviteCategorieClassification from "@/models/notrexp/EbeActiviteCategorieClassification";
import EbeEtablissement from "@/models/notrexp/EbeEtablissement";
import EbePilotage from "@/models/EbePilotage";
import EbePrevEffectif from "@/models/EbePrevEffectif";
import PrevisionEcoActivite from "@/models/PrevisionEcoActivite";
import Financeur from "@/models/Financeur";
import TypeFinanceur from "@/models/TypeFinanceur";
import Region from "@/models/Region";
import Departement from "@/models/Departement";
import Commune from "@/models/Commune";
import Epci from "@/models/Epci";
import Smic from "@/models/Smic";
import Notification from "@/models/Notification";
import StatutJuridique from "@/models/StatutJuridique";
import AttachedS3File from "@/models/AttachedS3File";
import DepartementEntite from "@/models/notrexp/DepartementEntite";
import GroupEntite from "@/models/notrexp/GroupEntite";
import Entite from "@/models/notrexp/Entite";

import EbePlanInvestissement from "@/models/pilotage/EbePlanInvestissement";
import EbeCategorieInvestissement from "@/models/pilotage/EbeCategorieInvestissement";
import EbePrevisionCoutInvestissement from "@/models/pilotage/EbePrevisionCoutInvestissement";
import EbePlanInvestissementFinancement from "@/models/pilotage/EbePlanInvestissementFinancement";
import EbePlanTresorerie from "@/models/pilotage/EbePlanTresorerie.js";
import EbePrevisionPlanTresorerie from "@/models/pilotage/EbePrevisionPlanTresorerie.js";
import EbePrevisionPlan from "@/models/pilotage/EbePrevisionPlan.js";
import EbePlanFinancement from "@/models/pilotage/EbePlanFinancement.js";
import EbePrevisionPlanFinancement from "@/models/pilotage/EbePrevisionPlanFinancement.js";
import EbePrevisionEconomique from "@/models/pilotage/EbePrevisionEconomique.js";
import EbeRdvActivite from "@/models/pilotage/EbeRdvActivite.js";
import PrevisionEcoActiviteFinancement from "@/models/pilotage/PrevisionEcoActiviteFinancement.js";
import QuestionQualitative from "@/models/pilotage/QuestionQualitative.js";
import QuestionQualitativeEbeReponse from "@/models/pilotage/QuestionQualitativeEbeReponse.js";

import EbePrevisionEtpAnnee from "@/models/financement/previsionEtp/EbePrevisionEtpAnnee.js";
import EbePrevisionEtpMois from "@/models/financement/previsionEtp/EbePrevisionEtpMois.js";

import DSNImport from "@/models/financement/DSNImport.js";
import DSNImportSalarie from "@/models/financement/DSNImportSalarie.js";
import DSNImportSalarieRemunerationCorrection from "@/models/financement/DSNImportSalarieRemunerationCorrection";
import DSNImportSummary from "@/models/financement/DSNImportSummary";
import Salarie from "@/models/financement/Salarie.js";
import EbeEtpRealiseFinaliseMensuel from "@/models/financement/EbeEtpRealiseFinaliseMensuel";

import FECImport from "@/models/fec/FECImport.js";
import FECActiviteRealisee from "@/models/fec/FECActiviteRealisee.js";
import FECDemandeCTE from "@/models/fec/FECDemandeCTE";
import CTEVersement from "@/models/fec/CTEVersement";

import PilotageCle from "@/models/pilotage_cle/PilotageCle";
import PilotageCleRealise from "@/models/pilotage_cle/PilotageCleRealise";
import PilotageCleProspectFreeze from "@/models/pilotage_cle/PilotageCleProspectFreeze";
import PilotageCleStrategieExhaustivite from "@/models/pilotage_cle/PilotageCleStrategieExhaustivite";
import PilotageClePrevision from "@/models/pilotage_cle/PilotageClePrevision";
import PilotageClePrevisionAnnee from "@/models/pilotage_cle/PilotageClePrevisionAnnee";
import PilotageClePrevisionSortieEbe from "@/models/pilotage_cle/PilotageClePrevisionSortieEbe";

import Individu from "@/models/notrexp/Individu.js";
import CleRepriseVolontaires from "@/models/volunteersManagement/CleRepriseVolontaires";
import IndividuContact from "@/models/volunteersManagement/IndividuContact.js";
import ProspectInfo from "@/models/volunteersManagement/ProspectInfo.js";
import ProspectStatus from "@/models/volunteersManagement/ProspectStatus.js";
import ProspectInfoSimilar from "@/models/volunteersManagement/ProspectInfoSimilar.js";
import ProspectInfoAdministrative from "@/models/volunteersManagement/ProspectInfoAdministrative.js";
import ProspectInfoEligibilite from "@/models/volunteersManagement/ProspectInfoEligibilite.js";
import ProspectInfoNotification from "@/models/volunteersManagement/ProspectInfoNotification";
import ProspectProfilProfessionnel from "@/models/volunteersManagement/ProspectProfilProfessionnel.js";
import ProspectPriseContact from "@/models/volunteersManagement/ProspectPriseContact.js";
import MoyenConnaissance from "@/models/volunteersManagement/selects/MoyenConnaissance.js";
import DroitsOuvert from "@/models/volunteersManagement/selects/DroitsOuvert.js";
import AccompagnementsSuivis from "@/models/volunteersManagement/selects/AccompagnementsSuivis.js";
import PermisConduite from "@/models/volunteersManagement/selects/PermisConduite.js";
import TypesContrat from "@/models/volunteersManagement/selects/TypesContrat.js";
import TypeIndicateurPde from "@/models/volunteersManagement/TypeIndicateurPde.js";

import DashboardPilotageEbe from "@/models/metabase/DashboardPilotageEbe";

import AgefiphSaisieSalarie from "@/models/agefiph/AgefiphSaisieSalarie";
import AgefiphSaisieEbe from "@/models/agefiph/AgefiphSaisieEbe";

import ContactProfile from "@/models/annuaire/ContactProfile";
import ContactProfilePrivate from "@/models/annuaire/ContactProfilePrivate";
import ContactProfileFonction from "@/models/annuaire/ContactProfileFonction";
import ContactProfileListeDiffusion from "@/models/annuaire/ContactProfileListeDiffusion";

import StaffSuivi from "@/models/suivi_territoire/StaffSuivi";
import SuiviTerritoireMeteoTerritoire from "@/models/suivi_territoire/SuiviTerritoireMeteoTerritoire";
import SuiviTerritoirePriseContact from "@/models/suivi_territoire/SuiviTerritoirePriseContact";
import SuiviTerritoirePriseContactMoyen from "@/models/suivi_territoire/SuiviTerritoirePriseContactMoyen";
import SuiviTerritoirePriseContactTheme from "@/models/suivi_territoire/SuiviTerritoirePriseContactTheme";
import SuiviTerritoirePriseContactTypeEchange from "@/models/suivi_territoire/SuiviTerritoirePriseContactTypeEchange";

import BilanGens from "@/models/bilan/BilanGens";
import BilanTerritoire from "@/models/bilan/BilanTerritoire";

VuexORM.use(VuexORMAxios, {
  axios: ApiRequest,
  baseURL: process.env.VUE_APP_API_URL,
});

const database = new VuexORM.Database();
database.register(ConstantData);
database.register(User);
database.register(UserActivityLog);
database.register(Group);
database.register(CdeEtat);
database.register(Cle);
database.register(Ebe);
database.register(EbeActiviteCategorie);
database.register(EbeActiviteCategorieClassification);
database.register(EbeEtablissement);
database.register(EbePilotage);
database.register(EbePrevEffectif);
database.register(PrevisionEcoActivite);
database.register(Financeur);
database.register(TypeFinanceur);
database.register(Region);
database.register(Departement);
database.register(Commune);
database.register(Epci);
database.register(Smic);
database.register(Notification);
database.register(StatutJuridique);
database.register(AttachedS3File);
database.register(DepartementEntite);
database.register(GroupEntite);
database.register(Entite);

database.register(CleRepriseVolontaires);

database.register(EbePlanInvestissement);
database.register(EbeCategorieInvestissement);
database.register(EbePrevisionCoutInvestissement);
database.register(EbePlanInvestissementFinancement);
database.register(EbePlanTresorerie);
database.register(EbePrevisionPlan);
database.register(EbePrevisionPlanTresorerie);
database.register(EbePlanFinancement);
database.register(EbePrevisionPlanFinancement);
database.register(EbePrevisionEconomique);
database.register(EbeRdvActivite);
database.register(PrevisionEcoActiviteFinancement);
database.register(QuestionQualitative);
database.register(QuestionQualitativeEbeReponse);

database.register(EbePrevisionEtpAnnee);
database.register(EbePrevisionEtpMois);
database.register(DSNImport);
database.register(DSNImportSalarie);
database.register(DSNImportSalarieRemunerationCorrection);
database.register(DSNImportSummary);
database.register(Salarie);
database.register(EbeEtpRealiseFinaliseMensuel);

// Agefiph
database.register(AgefiphSaisieEbe);
database.register(AgefiphSaisieSalarie);

// FEC
database.register(FECImport);
database.register(FECActiviteRealisee);
database.register(FECDemandeCTE);
database.register(CTEVersement);

// Pilotage CLE
database.register(PilotageCle);
database.register(PilotageCleRealise);
database.register(PilotageCleProspectFreeze);
database.register(PilotageCleStrategieExhaustivite);
database.register(PilotageClePrevision);
database.register(PilotageClePrevisionAnnee);
database.register(PilotageClePrevisionSortieEbe);

// Suivi volontaires (SV)
database.register(Individu);
database.register(IndividuContact);
database.register(ProspectInfo);
database.register(ProspectStatus);
database.register(ProspectInfoSimilar);
database.register(ProspectInfoAdministrative);
database.register(ProspectInfoEligibilite);
database.register(ProspectInfoNotification);
database.register(ProspectProfilProfessionnel);
database.register(ProspectPriseContact);

// (SV) - Selects
database.register(MoyenConnaissance);
database.register(DroitsOuvert);
database.register(AccompagnementsSuivis);
database.register(PermisConduite);
database.register(TypesContrat);
database.register(TypeIndicateurPde);

// Metabase
database.register(DashboardPilotageEbe);

// Annuaire
database.register(ContactProfile);
database.register(ContactProfilePrivate);
database.register(ContactProfileFonction);
database.register(ContactProfileListeDiffusion);

// Suivi territoire
database.register(StaffSuivi);
database.register(SuiviTerritoireMeteoTerritoire);
database.register(SuiviTerritoirePriseContact);
database.register(SuiviTerritoirePriseContactMoyen);
database.register(SuiviTerritoirePriseContactTheme);
database.register(SuiviTerritoirePriseContactTypeEchange);

// Bilan des territoires et des gens
database.register(BilanGens);
database.register(BilanTerritoire);

export default database;
