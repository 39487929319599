import BaseModel from "@/models/BaseModel";
import { downloadFile } from "@/helpers/ApiRequest";

export default class AgefiphSaisieSalarie extends BaseModel {
  static entity = "agefiph_saisie_salarie";
  static resourceUrl = "/agefiph/agefiph_saisie_salarie/";

  static fields() {
    return {
      id: this.uid(),
      user: this.attr(),
      ebe: this.attr(),
      salarie: this.attr(),
      annee: this.attr(),
      reponse_boeth: this.attr(),
      reponse_en_cours_date_depot: this.attr(),
      reponse_niveau_formation: this.attr(),
      reponse_encadrement: this.attr(),
    };
  }

  static apiConfig = {
    actions: {
      async exportXlsx(annee) {
        const url = `${process.env.VUE_APP_API_URL}${AgefiphSaisieSalarie.resourceUrl}export_xlsx/?annee__year=${annee}`;
        await downloadFile(url);
      },
      async availableAnnees() {
        const result = await this.get(`${this.model.resourceUrl}available_annees/`);
        return result.response.data;
      },
      ...BaseModel.apiConfig.actions,
    },
  };
}
