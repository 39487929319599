import BaseModel from "@/models/BaseModel";

export default class Entite extends BaseModel {
  static entity = "entite";
  static resourceUrl = "/notrexp/entite/";

  static fields() {
    return {
      id: this.uid(),
    };
  }
}
